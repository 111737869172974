import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "~/components/Inputs/Input";
import Button from "../../../Form/Button";
import { contactRequest } from "~/utils/Fetch/Funnel";
import { useEffect, useState, useRef } from "react";
import Tel from "../../../Inputs/Tel";
import { COLORS } from "../../../../config/themes";
import TextArea from "~/components/Inputs/TextArea";
import {
  postFunnelAnalytics,
  stepLoaded,
} from "~/components/Agents/MessageAgent/Analytics";
import { useRouter } from "next/router";
import { trackVirtualPageView } from "../../../../utils/Fetch/Analytics";
import { getLeadUUID, setLeadCreatedUUID } from "../../../../utils/UUID";
import { nameRegexString } from "~/utils/Constants/RegexStrings";
import { capitalize } from "../../../../utils/TextProcessing";
import Select from "~/components/Inputs/Select";
import styles from "./ContactForm.module.scss";
import Toggle from "~/components/atoms/Toggle";

const ContactForm = ({
  agent,
  nextScreen,
  displayError,
  funnel,
  setOtherAgents,
  clearInlineMessageStoredValues,
  getInlineMessagesStoredValues,
  isPrime,
  isNonPrime,
  city,
  state,
}) => {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState();
  const [country, setCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checkValidation, setCheckValidation] = useState(false);
  const [messageSubject, setMessageSubject] = useState(null);
  const [showMessageSubjectError, setShowMessageSubjectError] = useState(null);
  const [currentlyWorkingWithAgent, setCurrentlyWorkingWithAgent] = useState(
    false
  );
  const [messageOtherAgents, setMessageOtherAgents] = useState(false);
  const [tooltipReady, setTooltipReady] = useState(false);
  const [funnelStarted, setFunnelStarted] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [consent, setConsent] = useState({});

  const screenKey = "ContactForm";
  const router = useRouter();
  const fullNameInputRef = useRef();
  const emailInputRef = useRef();
  const telInputRef = useRef();
  const messageInputRef = useRef();
  const tooltipTimeout = useRef();

  useEffect(() => {
    clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = setTimeout(() => {
      setTooltipReady(true);
    }, 1100);
  }, [router.asPath]);

  const agentName = agent.display_name
    ? agent.display_name.split(" ")[0]
    : "Agent";

  const onFullNameChange = (value) => {
    setFullName(value);
    if (getInlineMessagesStoredValues) {
      localStorage.setItem("inline_message_name", value);
    }
  };

  const onEmailChange = (value) => {
    setEmail(value.trim());
    if (getInlineMessagesStoredValues) {
      localStorage.setItem("inline_message_email", value);
    }
  };

  const onMessageChange = (value) => {
    setMessage(value);
    if (getInlineMessagesStoredValues) {
      localStorage.setItem("inline_message_message", value);
    }
  };

  const onMessageOtherAgentsChange = (e) => {
    setMessageOtherAgents(e.target.checked);
    setOtherAgents(e.target.checked);
  };

  const onCurrentlyWorkingWithAgentChange = (e) => {
    setCurrentlyWorkingWithAgent(e.target.checked);
  };

  useEffect(() => {
    if (getInlineMessagesStoredValues) {
      const storedValues = getInlineMessagesStoredValues();
      setFullName(storedValues.name || null);
      setEmail(storedValues.email || null);
      setPhone(storedValues.phone || "");
      setMessage(storedValues.message || null);
      setCountry(storedValues.country || "US");
    }
    stepLoaded({ key: "ContactForm" }, router, agent.rea_agent_status, funnel);
  }, []);

  const onInputFocus = () => {
    if (!funnelStarted && funnel !== "inline_message_mobile") {
      trackVirtualPageView("funnel_start", funnel);
      setFunnelStarted(true);
    }
  };

  const whichLeadType = () => {
    let leadType = null;

    switch (messageSubject) {
      case "Interested in Buying":
      case "Message Only":
        leadType = "buyer";
        break;
      case "Interested in Selling":
        leadType = "seller";
        break;
      case "Interested in Buying and Selling":
        leadType = "both";
        break;
    }

    return leadType;
  };

  const whichClientType = () => {
    let clientType = null;

    switch (messageSubject) {
      case "Interested in Buying":
      case "Message Only":
        clientType = "buying";
        break;
      case "Interested in Selling":
        clientType = "selling";
        break;
      case "Interested in Buying and Selling":
        clientType = "buying and selling";
        break;
    }

    return clientType;
  };

  const submit = async () => {
    if (!isPrime && !isNonPrime) {
      setOtherAgents(true);
    }
    if (messageSubject === null || messageSubject === "") {
      return;
    }

    let data = {};
    const lead_type = whichLeadType();

    data.lead = {
      message: message,
      country: "US",
      country_code: "1",
      agent_id: agent.agent_id || null,
      force_match: true,
      other_agents: !isPrime && !isNonPrime ? true : messageOtherAgents,
      funnel,
      message_subject: messageSubject,
      working_with_agent: currentlyWorkingWithAgent,
      lead_type: lead_type,
    };

    data.user = {
      name: fullName.trim(),
      email: email,
      phonenumber: phone !== null && phone !== "" ? phone.trim() : null,
    };

    let new_lead_type = phoneIsValid ? "rea" : "permissive";

    if (!isPrime && !isNonPrime) {
      switch (lead_type) {
        case "buyer":
          data.lead.buyer_cities = `${capitalize(
            city
          )}, ${state.toUpperCase()}`;
          break;
        case "seller":
        case "both":
          data.lead.city = capitalize(city);
          data.lead.state = state.toUpperCase();
          data.lead.street_number = "Address";
          data.lead.street_name = "Not Disclosed";
          break;
      }

      data.lead.other_agents = true;
      data.lead.force_match = false;
    }

    const consent_text_el = document.getElementById("contact-consent-text");
    data.consent = {
      ...consent,
      url: window.location.href,
      source: funnel,
      consent_text_original: `By clicking "Message ${
        agent.display_name ? agent.display_name.split(" ")[0] : "Agent"
      }" above, you consent, acknowledge and agree to our Terms of Use and Privacy Policy\
      which includes binding arbitration and consent to receive\
      electronic communications, you are providing express written\
      consent for RealEstateAgents.com and our participating agents to contact you at the phone number\
      you provided via calls and/or SMS, MMS using an automated\
      dialing system to provide agent info. even if your number is\
      listed on an internal, corporate, state or federal Do-Not-Call\
      list. You may revoke this consent any time. Consent is not a\
      condition for our service.`
        .replace(/\s+/g, " ")
        .trim(),
      consent_text_shown: consent_text_el
        ? consent_text_el.textContent
        : consent.consent_text_shown,
      email_consent_given: true,
      text_consent_given: true,
      phone_consent_given: true,
    };
    setConsent(data.consent);

    await contactRequest({
      ...data,
      inline_message_request: true,
      new_lead_type: new_lead_type,
    }).then(() => {
      const clientType = whichClientType();
      trackVirtualPageView("lead_created", funnel, clientType);
      const lead_uuid = getLeadUUID();
      setLeadCreatedUUID(lead_uuid);
    });

    postFunnelAnalytics(
      {
        key: "ContactForm",
        value: {
          ...data,
        },
      },
      router,
      agent.rea_agent_status,
      funnel,
      true
    );
  };

  const renderMessageSubjectOptions = () => {
    const options = [
      { label: "Interested in Buying", clientType: "buyer" },
      { label: "Interested in Selling", clientType: "seller" },
      { label: "Interested in Buying and Selling", clientType: "both" },
      { label: "Message Only", clientType: "buyer" },
    ];

    return options.map((option, index) => {
      return (
        <option value={option.label} key={index}>
          {option.label}
        </option>
      );
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setCheckValidation(true);
    setLoading(true);

    if (messageSubject === null || messageSubject === "") {
      setShowMessageSubjectError(true);
      setLoading(false);
      return;
    }

    if (
      !fullNameInputRef?.current?.checkValidity() ||
      !emailInputRef?.current?.checkValidity() ||
      !messageInputRef?.current?.checkValidity()
    ) {
      setLoading(false);
      return;
    }

    submit()
      .then(() => {
        setLoading(false);
        setCheckValidation(false);
        if (clearInlineMessageStoredValues) {
          clearInlineMessageStoredValues();
        }
        nextScreen({ key: screenKey });
      })
      .catch((e) => {
        setLoading(false);
        if (displayError) {
          displayError();
        } else {
          setError(`Error! Something went wrong! Please try again later.`);
        }
      });
  };

  const customErrorMessage = ({ el }) => {
    let errorMessages = [];
    if (el.validity.valueMissing) {
      errorMessages.push({
        message: `Please enter your message`,
        type: "valueMissing",
      });
    }
    return errorMessages;
  };

  const onMessageSubjectSelect = (value) => {
    setMessageSubject(value);
    setShowMessageSubjectError(false);
  };

  return (
    <>
      <form className={css(stylesOld.formContainer)} onSubmit={handleClick}>
        <Input
          label={"Full Name"}
          onChange={onFullNameChange}
          name={"inline-client-full-name"}
          value={fullName || ""}
          pattern={nameRegexString}
          autoComplete="name"
          required={true}
          maxlength="255"
          inputStyle={css(stylesOld.input)}
          checkValidation={checkValidation}
          inputRef={fullNameInputRef}
          onFocus={onInputFocus}
          labelStyle={css(stylesOld.label)}
          inlineFunnel={true}
        />
        <Input
          label={"Email"}
          onChange={onEmailChange}
          type={"email"}
          name={"inline-client-email"}
          value={email || ""}
          autoComplete="email"
          required={true}
          maxlength="255"
          inputStyle={css(stylesOld.input)}
          checkValidation={checkValidation}
          inputRef={emailInputRef}
          onFocus={onInputFocus}
          labelStyle={css(stylesOld.label)}
          inlineFunnel={true}
        />
        <Tel
          label={"Phone"}
          onChange={(e, internalValue, displayValue) => {
            setPhone(internalValue);
            localStorage.setItem("inline_message_phone", internalValue);
          }}
          name={"inline-client-phone"}
          value={phone || ""}
          autoComplete="tel"
          required={false}
          initialCountry={country}
          setPhoneCountry={(value) => {
            setCountry(value);
            localStorage.setItem("inline_message_country", value);
          }}
          inputStyle={css(stylesOld.input)}
          buttonStyle={css(stylesOld.countryButton)}
          labelStyle={css(stylesOld.label)}
          wrapperStyle={css(stylesOld.input)}
          checkValidation={checkValidation}
          inputRef={telInputRef}
          onFocus={onInputFocus}
          setPhoneIsValid={setPhoneIsValid}
          inlineFunnel={true}
          doNotValidate={true}
        />
        <Select
          label={"Subject"}
          onChange={onMessageSubjectSelect}
          value={messageSubject}
          customErrorMessage={"Please choose a message subject"}
          showError={showMessageSubjectError}
          wrapperClassName={"--inline-funnel"}
          onFocus={onInputFocus}
          inlineFunnel={true}
        >
          {renderMessageSubjectOptions()}
        </Select>
        <TextArea
          label={
            isPrime || isNonPrime
              ? `Enter your message to ${agentName}`
              : `Your message to share with a few carefully selected top ${capitalize(
                  city
                )}, ${state.toUpperCase()} Agents`
          }
          onChange={onMessageChange}
          name={"inline-client-needs"}
          value={message}
          required={true}
          maxlength="20000"
          customErrorMessage={customErrorMessage}
          inputStyle={css(stylesOld.input, stylesOld.textArea)}
          checkValidation={checkValidation}
          inputRef={messageInputRef}
          onFocus={onInputFocus}
          inlineFunnel={true}
          altActive={!isPrime && !isNonPrime}
        />
        {(isPrime || isNonPrime) && (
          <Toggle
            name="currently-working-with-agent"
            checked={currentlyWorkingWithAgent}
            className={"--full-width --inline --inline-funnel"}
            onChange={onCurrentlyWorkingWithAgentChange}
            onFocus={onInputFocus}
          >
            <span className={styles["Toggle__label"]}>
              I am currently working with {agentName}
            </span>
          </Toggle>
        )}
        <Button
          text={
            isPrime || isNonPrime
              ? `Message ${agentName}`
              : `Get Connected With Top Agents`
          }
          icon={
            loading ? (
              <FontAwesomeIcon
                icon={["fad", "spinner-third"]}
                spin
                className={css(stylesOld.load)}
              />
            ) : (
              <FontAwesomeIcon icon={["fas", "envelope"]} />
            )
          }
          iconStyles={stylesOld.buttonIcon}
          buttonStyles={stylesOld.buttonStyle}
          disabled={loading}
        />
      </form>
    </>
  );
};

const stylesOld = StyleSheet.create({
  input: {
    display: "flex",
    background: "#F8F8F8",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: 8,
  },
  phoneInputContainer: {
    paddingLeft: 10,
  },
  formContainer: {
    "@media only screen and (max-width: 1439px)": {
      rowGap: 12,
    },
    "@media only screen and (max-width: 1023px)": {
      rowGap: 16,
    },

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginTop: 16,
    rowGap: 16,
  },
  textArea: {
    height: 150,
    resize: "none",
    padding: 10,
    outline: "none",
  },
  phoneButton: {
    background: "transparent",
    border: "none",

    ":hover": {
      background: "transparent",
    },
  },
  phoneInput: {
    background: "#F8F8F8",
    border: "none",
    height: 60,
  },
  buttonIcon: {
    position: "relative",
    top: 0,
    left: 0,
    transform: "none",
    marginRight: 10,
  },
  im: {
    marginRight: 5,
  },
  radioButtonGroup: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    columnGap: 24,
  },
  selectedRadio: {
    border: "4px solid",
    borderColor: COLORS.PRIMARY_ACTION(),
    borderRadius: 12,
    background: "#F8F8F8",
  },
  load: {
    "--fa-primary-color": "rgba(234, 88, 12, 1)",
    "--fa-primary-opacity": 1,
  },
  successMessageHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: "1px dashed",
    paddingBottom: 10,
    marginBottom: 10,
  },
  messageSent: {
    fontSize: 18,
    fontWeight: 600,
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 8,
  },
  error: {
    color: "#cc0000",
  },
  input: {
    background: "#FFF",
    height: 40,
    fontSize: 14,
  },
  countryButton: {
    background: "#fff",
    borderRight: "1px solid #F3F3F3",
    "::after": {
      top: 15,
    },
  },
  textArea: {
    height: 80,
  },
  label: {
    top: 12,
    fontSize: 14,
  },
  buttonStyle: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});
export default ContactForm;
